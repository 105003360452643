<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
        <el-tooltip  placement="top" content="双击调整任务类型顺序">
        <!--列表内容-->
          <el-table
            :data="users" 
            highlight-current-row
            @current-change="selectCurrentRow"
            :row-style="{height:'40px'}"
            :cell-style="{padding:'0px'}"
            :header-cell-style="{background:'#dce4f1',color:'#000000'}"
            :row-class-name="tableRowClassName"
            @row-dblclick="editInfo"
            style="width: 100%">
             <el-table-column type = "index" width = "80"></el-table-column>
             <el-table-column label="任务类型名称" prop="Name" align="center"></el-table-column>
             <el-table-column label="添加时间" prop="AddTime" :formatter="formatCreateTime" align="center"></el-table-column>
             <el-table-column label="是否启用" prop="Enabled" align="center">
                 <template slot-scope = "scope">
                    <el-tag v-if="scope.row.Enabled == 0" type="danger">
                        否
                    </el-tag>
                    <el-tag v-else type="success">是</el-tag>
                </template>
             </el-table-column>
             <el-table-column label="序号" prop="DisplayOrder" align="center"></el-table-column>
            </el-table>
        </el-tooltip>
            <!--新增界面-->
            <el-dialog 
                :visible.sync="addFormVisible" 
                v-model="addFormVisible"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                @close="close"
                center
                style="width: 60%; left:20%;" 
                >
                <template slot="title">
                    <span style="color:#FFFFFF;font-size: 16px;">新增任务类型</span>
                </template>
                  <el-form :model="addForm" :rules="addFormRules" ref="addForm">
                      <el-form-item prop="Name">
                          <el-row>
                              <el-col :span="0.1">
                                  <span style="color: red;">*</span>
                              </el-col>
                              <el-col :span="5">
                                   <span>任务类型名称:</span>
                              </el-col>
                             <el-col :span="18">
                                 <el-input v-model="addForm.Name" ref="input"></el-input>
                             </el-col>
                          </el-row>
                      </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button type="goon" @click.native="addSubmit">提交</el-button>
                </div>
            </el-dialog>
            <!--修改序列号界面-->
             <el-dialog
                :visible.sync="editFormVisible" 
                v-model="editFormVisible"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                style="width: 60%; left:20%;"
                center 
             >
             <template slot="title">
                <span style="color:#FFFFFF;font-size: 16px;">编辑</span>
            </template>
             <el-form :model="editForm" label-width="55px" :rules="editFormRules">
                      <el-form-item label="序号:" prop="DisplayOrder">
                          <el-input v-model="editForm.DisplayOrder" type="number"></el-input>
                      </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button type="goon" @click.native="editSubmit">提交</el-button>
                </div>
             </el-dialog>
    </section>
</template>
<script>
import util from '../../../util/date';
import {Loading} from 'element-ui'
import {QueryAllClassifyList,AddTaskCommonClassify,DeleteTaskCommonClassify,UpdateTaskCommonClassify} from '../../api/oa';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
export default {
    components: { Toolbar },
    data() {
        return{
            loading: '',
            users: [],
            buttonList: [],
            currentRow: null,
            foc: false,
            filters: {
                name: ''
            },
            addFormVisible: false,   //新增界面初始化
            addForm: {
                Name: null
            },
            addFormRules: {
                Name: [
                    {required: true, message: '请输入任务类型名称'}
                ]
            },
            editFormVisible: false,
            editForm: {
                Id: null,
                DisplayOrder: null
            },
            editFormRules: {
                DisplayOrder: [
                    {required: true, message: '请输入任务类型序号', trigger: 'blur'}
                ]
            },
            buttonListmsg: 'tasktype'
        }
    },
    methods: {
        selectCurrentRow(val) {
            this.currentRow = val;
        },
            callFunction(item) {
                this.filters = {
                name: item.search
            };
            this[item.Func].apply(this, item);
        
        },
         formatCreateTime: function (row,colume) {
            return (!row.AddTime || row.AddTime == '') ? '' : util.formatDate.format(new Date(row.AddTime),'yyyy-MM-dd');
        },
        getTypes(){
            QueryAllClassifyList().then((res) => {
                this.users = res.data.response;
            });
        },
        //新增
        handleAdd(){
            this.addFormVisible = true;
            this.addForm = {
                Name: null
            }
            this.$nextTick(() => {
                this.$refs.input.focus();
            });

        },
        //新增提交
        addSubmit(){
            this.$refs.addForm.validate((valid) => {
                if(valid){
                    this.Loadingstart();
                    let para = Object.assign({},this.addForm);
                    AddTaskCommonClassify(para).then((res) => {
                         if (res.data.success) {
                            this.$message({
                            message: res.data.msg,
                            type: "success"
                            });
                            this.Loadignend();
                            this.$refs["addForm"].resetFields();
                            this.addFormVisible = false;
                            this.getTypes();
                        } else {
                            this.$message({
                            message: res.data.msg,
                            type: "error"
                            });
                        }
                    });
                }
            });
        },
        //禁用
        handleDelete(){
            let row = this.currentRow;
               if (!row) {
               this.$message({
                message: "请选择要禁用的一行数据！",
                type: "error",
                duration: 10000
             });
                return;
           }
           if(row.Name == "日常工作"){
            
                this.$message({
                    message: "日常工作不允许禁用。",
                    type: "warning",
                    duration: 10000
                });
                return;
          
           }
           if(row.CommonTaskClassify == 28){
               this.$message({
                    message: "领导安排不允许禁用。",
                    type: "warning",
                    duration: 10000
                });
                return;
           }
           let para = {id: row.Id,enabled: false};
           DeleteTaskCommonClassify(para).then((res) => {
               if (res.data.success) {
                    this.$message({
                    message: res.data.msg,
                    type: "success"
                    });
                    this.currentRow = null;
                    this.getTypes();
                } else {
                    this.$message({
                    message: res.data.msg,
                    type: "error"
                    });
                }
           });
        },
        handleEnbled(){
            let row = this.currentRow;
               if (!row) {
               this.$message({
                message: "请选择要启用的一行数据！",
                type: "error",
                duration: 10000
             });
                return;
           }
           let para = {id: row.Id,enabled: true};
           DeleteTaskCommonClassify(para).then((res) => {
               if (res.data.success) {
                    this.$message({
                    message: res.data.msg,
                    type: "success"
                    });
                    this.currentRow = null;
                    this.getTypes();
                } else {
                    this.$message({
                    message: res.data.msg,
                    type: "error"
                    });
                }
           });
        },
        //修改序列号
        editInfo(row){
            this.editFormVisible = true;
            this.editForm = Object.assign({},row);
        },
        //修改提交
        editSubmit(){
            this.Loadingstart();
            let para = Object.assign({},this.editForm);
            UpdateTaskCommonClassify(para).then((res) => {
                 if (res.data.success) {
                    this.$message({
                    message: res.data.msg,
                    type: "success"
                    });
                    this.Loadignend();
                    this.editFormVisible = false;
                    this.getTypes();
                } else {
                    this.$message({
                    message: res.data.msg,
                    type: "error"
                    });
                }
            });
        },
        close(){
           this.$refs['addForm'].resetFields();
        },
         tableRowClassName({row, rowIndex}){
            if (rowIndex % 2 !== 0) {
            return 'warning-row';
            }
            return '';    
       },
       Loadingstart(){
        　　this.loading = Loading.service({
        　　　　lock:true,
        　　　　text:'加载中...',
        　　　　background:'rgba(0,0,0,0.1)'
        　　})
        },
        Loadignend(){
        　　this.loading.close();
        }
    },
    mounted() {
            this.getTypes();
            let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
             : [];
            //第一种写法，每个页面都需要写方法，但是可以做特性化处理
            // this.getButtonList(routers);
            
            //第二种写法，封装到 permissionRouter.js 中
    let  buttons = window.localStorage.buttList? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
        
    }
}
</script>
<style scoped>
</style>